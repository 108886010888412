import { LazyExoticComponent, lazy, useMemo } from 'react';

const LandingPage = lazy(() => import('Components/LandingPage'));
const LoginPage = lazy(() => import('Components/auth/Login'));
const ForgotPasswordPage = lazy(() => import('Components/auth/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('Components/auth/ResetPassword'));
const RegisterPage = lazy(() => import('Components/register/CreateNewUser'));
const AccountInformation = lazy(() => import('Components/settings/AccountInformation'));
const UpdatePasswordPage = lazy(() => import('Components/settings/UpdatePassword'));
const HipaaConsent = lazy(() => import('Components/consents/HipaaConsent'));
const RWEConsent = lazy(() => import('Components/consents/RWEConsent'));
const Document = lazy(() => import('Components/consents/Document'));
const VerifyConsents = lazy(() => import('Components/consents/VerifyConsents'));
const Consents = lazy(() => import('Components/consents/ConsentsScreen'));
const InitializationErrorPage = lazy(() => import('Components/utility/InitializationErrorPage'));

const routes: { route: string; component: LazyExoticComponent<any>; props?: any }[] = [
  { route: 'login', component: LoginPage },
  { route: 'create-account', component: RegisterPage },
  { route: 'forgot-password', component: ForgotPasswordPage },
  { route: 'reset-password', component: ResetPasswordPage },
  { route: 'account-information', component: AccountInformation },
  { route: 'update-password', component: UpdatePasswordPage },
  { route: 'hipaa', component: HipaaConsent },
  { route: 'rwe', component: RWEConsent },
  { route: 'terms-of-use', component: Document, props: { type: 'toufsll' } },
  { route: 'privacy-notice', component: Document, props: { type: 'pnpat' } },
  { route: 'verify-consents', component: VerifyConsents },
  { route: 'consents', component: Consents },
];

const useRouter = (location: string, queryString: string, initErrorStatus?: number) => {
  const { Component, props } = useMemo(() => {
    if (initErrorStatus) {
      return {
        Component: InitializationErrorPage,
        props: { errorStatus: initErrorStatus, navigationQueryString: queryString },
      };
    }

    const lowerCaseLocation = location.toLowerCase();

    const route = routes.find((r) => lowerCaseLocation.startsWith(`/${r.route.toLowerCase()}`));

    if (route) {
      return { Component: route.component, props: route.props };
    }

    return { Component: LandingPage };
  }, [initErrorStatus, location, queryString]);

  return { Component, props };
};

export default useRouter;
