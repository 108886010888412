import React, { useCallback, useEffect, useState } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { AxiosError } from 'axios';
import { Box, Button, Divider, HStack, Stack } from 'native-base';

import { RichText } from 'Components/utility/RichText/RichText';

import { CLOSE_MODAL, ModalProps, SHOW_MODAL } from 'Reducers/modal/types';

import { getDifferenceInMinutesFromNow } from 'Utilities/dateHelpers';
import i18n from 'Utilities/i18n';
import { logout } from 'Utilities/session';
import { getSessionObject, removeSessionObject, setSessionObject } from 'Utilities/storage';
import { store } from 'Utilities/store';

interface ITranslationKeys {
  lockoutMessageTitleKey: string;
  lockoutMessageSubtitleKey: string;
  authErrorTitleKey: string;
  authErrorAttemptsRemainingKey: string;
  authErrorLockedKey: string;
}

const translationsKeys: { login: ITranslationKeys; changepassword: ITranslationKeys } = {
  login: {
    lockoutMessageTitleKey: 'Login.modals.signInErrors.accountLocked',
    lockoutMessageSubtitleKey: 'Login.modals.signInErrors.accountLockedPeriod',
    authErrorTitleKey: 'Login.modals.signInErrors.loginFailure',
    authErrorAttemptsRemainingKey: 'Login.modals.signInErrors.attemptsRemaining',
    authErrorLockedKey: 'Login.modals.signInErrors.accountWillBeLocked',
  },
  changepassword: {
    lockoutMessageTitleKey: 'ChangePassword.microcopy.lockoutBanner.messageItem_1',
    lockoutMessageSubtitleKey: 'ChangePassword.microcopy.lockoutBanner.messageItem_2',
    authErrorTitleKey: 'ChangePassword.modals.changePasswordErrors.attemptsRemainingError.title',
    authErrorAttemptsRemainingKey:
      'ChangePassword.modals.changePasswordErrors.attemptsRemainingError.message',
    authErrorLockedKey:
      'ChangePassword.modals.changePasswordErrors.noAttemptsRemainingError.message',
  },
};

const useAuthError = (type?: 'changepassword' | 'login') => {
  const [isLocked, setIsLocked] = useState(false);
  const [lockoutMessage, setLockoutMessage] = useState('');
  const [lockoutSubmessage, setlockoutSubmessage] = useState('');

  const [translationKeys, setTranslationKeys] = useState<ITranslationKeys>({
    lockoutMessageTitleKey: '',
    lockoutMessageSubtitleKey: '',
    authErrorTitleKey: '',
    authErrorAttemptsRemainingKey: '',
    authErrorLockedKey: '',
  });

  useEffect(() => {
    if (type) {
      const translationKeys = translationsKeys[type];

      setTranslationKeys(translationKeys);
    }
  }, [type]);

  const handleLockoutError = (error: AxiosError) => {
    const attemptsResetAfter = Number(error.response?.headers['x-attempts-reset-after']);
    const attemptsResetAt = Number(error.response?.headers['x-attempts-reset-at']);

    showLockoutMessage(attemptsResetAt, attemptsResetAfter);
  };

  const showLockoutMessage = useCallback(
    (attemptsResetAt: number, attemptsResetAfter: number) => {
      const { time, difference } = getDifferenceInMinutesFromNow(attemptsResetAt);

      if (difference < 0) {
        return;
      }

      setIsLocked(true);

      setLockoutMessage(i18n.t<string>(translationKeys.lockoutMessageTitleKey));
      setlockoutSubmessage(i18n.t<string>(translationKeys.lockoutMessageSubtitleKey, { time }));

      const lockoutInterval = setInterval(() => {
        const { time, difference } = getDifferenceInMinutesFromNow(attemptsResetAt);

        if (difference < 0) {
          clearInterval(lockoutInterval);
          setLockoutMessage('');
          setlockoutSubmessage('');
          setIsLocked(false);
          removeSessionObject('loginLockout');
        } else {
          setlockoutSubmessage(i18n.t<string>(translationKeys.lockoutMessageSubtitleKey, { time }));
        }
      }, 1000);

      setSessionObject<LoginLockout>('loginLockout', {
        attemptsResetAt,
        lockoutInterval,
        attemptsResetAfter,
      });
    },
    [translationKeys.lockoutMessageSubtitleKey, translationKeys.lockoutMessageTitleKey]
  );

  useEffect(() => {
    const loginLockout = getSessionObject<LoginLockout>('loginLockout');

    if (loginLockout) {
      showLockoutMessage(loginLockout.attemptsResetAt, loginLockout.attemptsResetAfter);
    }

    return () => {
      const loginLockout = getSessionObject<LoginLockout>('loginLockout');

      if (loginLockout) {
        clearInterval(loginLockout.lockoutInterval);
      }
    };
  }, [showLockoutMessage]);

  const showLoginFailure = useCallback(
    (
      attemptsRemaining = -1,
      attemptsResetAfter?: number,
      attemptsResetAt?: number,
      onCloseAfterLockout?: () => void,
      onClose?: () => void
    ) => {
      const modalProps: ModalProps = {};

      modalProps.body = (
        <Stack space={4}>
          <Text
            color="text.100"
            fontWeight={'bodyBaseDefault'}
            fontFamily={'bodyBaseDefault'}
            fontSize="base"
            nativeID={translationKeys.authErrorTitleKey}
            mb={1}
          >
            {i18n.t<string>(translationKeys.authErrorTitleKey)}
          </Text>
          {attemptsRemaining >= 0 ? (
            <Box display="inline-block">
              <Text
                color="text.100"
                fontWeight={'bodyBaseDefault'}
                fontFamily={'bodyBaseDefault'}
                fontSize="base"
                nativeID={translationKeys.authErrorAttemptsRemainingKey}
              >
                {i18n.t<string>(translationKeys.authErrorAttemptsRemainingKey, {
                  count: attemptsRemaining,
                })}
              </Text>

              {attemptsResetAfter ? (
                <Text
                  color="text.100"
                  fontToken="bodyBaseMedium"
                  nativeID={translationKeys.authErrorLockedKey}
                >
                  {i18n.t<string>(translationKeys.authErrorLockedKey, {
                    time: attemptsResetAfter,
                    duration: attemptsResetAfter,
                  })}
                </Text>
              ) : null}
            </Box>
          ) : null}
        </Stack>
      );

      modalProps.onClose = () => {
        onClose && onClose();
      };

      if (attemptsResetAfter && attemptsResetAt) {
        modalProps.onClose = () => {
          showLockoutMessage(attemptsResetAt, attemptsResetAfter);
          onCloseAfterLockout && onCloseAfterLockout();
        };
      }

      store.dispatch({
        type: SHOW_MODAL,
        modalProps,
      });
    },
    [
      showLockoutMessage,
      translationKeys.authErrorAttemptsRemainingKey,
      translationKeys.authErrorLockedKey,
      translationKeys.authErrorTitleKey,
    ]
  );

  const handleLoginError = (
    error: AxiosError<ApiErrorData>,
    onCloseAfterLockout: () => void,
    onClose?: () => void
  ) => {
    if (error.response?.data.details === 'Authorization.isRequired') {
      logout();
      return;
    }

    if (error.response && error.response.headers['x-attempts-remaining']) {
      const attemptsRemaining = Number(error.response.headers['x-attempts-remaining']);
      const attemptsResetAt = error.response.headers['x-attempts-reset-at']
        ? Number(error.response.headers['x-attempts-reset-at'])
        : 0;
      const attemptsResetAfter = error.response.headers['x-attempts-reset-after']
        ? Number(error.response.headers['x-attempts-reset-after']) / 60
        : 0;

      showLoginFailure(
        attemptsRemaining,
        attemptsResetAfter,
        attemptsResetAt,
        onCloseAfterLockout,
        onClose
      );
      return;
    }

    showLoginFailure();
  };

  const showHcpError = () => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4} nativeID="Global.modals.blockHCPUser.body">
            <RichText
              text={i18n.t<string>('Global.modals.blockHCPUser.body', {
                appName: i18n.t<string>('Global.appNames.libreView'),
              })}
            />
          </Stack>
        ),
      },
    });
  };

  const showSessionManagementError = useCallback((onForceSignIn: () => void) => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4}>
            <RichText
              nativeID="Global.modals.supportNewNSInstanceLogin.body"
              text={i18n.t<string>('Global.modals.supportNewNSInstanceLogin.body', {
                appName: i18n.t<string>('Global.appNames.libreView'),
              })}
            />
          </Stack>
        ),
        footer: (
          <HStack
            width="100%"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            borderBottomColor="interactive.neutral.30"
          >
            <Button
              nativeID={'Global.microcopy.common.cancel'}
              variant="link"
              _text={{
                color: 'interactive.text.active',
                fontWeight: 'bodyBaseDefault',
                fontFamily: 'bodyBaseDefault',
                textDecoration: 'none',
                fontSize: 'base',
              }}
              marginX="auto"
              onPress={() => {
                store.dispatch({
                  type: CLOSE_MODAL,
                });
              }}
            >
              {i18n.t<string>('Global.microcopy.common.cancel')}
            </Button>
            <Divider orientation="vertical" />
            <Button
              nativeID={'Global.microcopy.common.okCap'}
              variant="link"
              _text={{
                color: 'interactive.text.active',
                fontWeight: 'bodyBaseMedium',
                fontFamily: 'bodyBaseMedium',
                textDecoration: 'none',
                fontSize: 'base',
              }}
              marginX="auto"
              onPress={() => {
                onForceSignIn();
                store.dispatch({
                  type: CLOSE_MODAL,
                });
              }}
            >
              {i18n.t<string>('Global.microcopy.common.okCap')}
            </Button>
          </HStack>
        ),
      },
    });
  }, []);

  const showTokenExpirationError = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack space={4}>
            <RichText
              nativeID="Global.modals.linkNotValid.title"
              text={i18n.t<string>('Global.modals.linkNotValid.title')}
            />
            <Text
              color="text.100"
              fontWeight="bodyBaseDefault"
              fontFamily="bodyBaseDefault"
              fontSize="base"
              nativeID="Global.modals.linkNotValid.body"
              mt={1}
            >
              {i18n.t<string>('Global.modals.linkNotValid.body')}
            </Text>
          </Stack>
        ),
      },
    });
  }, []);

  return {
    handleLoginError,
    handleLockoutError,
    showHcpError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
    showSessionManagementError,
    showTokenExpirationError,
  };
};

export default useAuthError;
