import { _config } from 'Utilities/envConfig';
import log from 'Utilities/log';

export type Topic =
  | 'adc-webview:signin'
  | 'adc-webview:navigation'
  | 'adc-webview:update-account'
  | 'adc-webview:update-password'
  | 'adc-webview:consents'
  | 'adc-webview:create-account';

export const notifyApp = <T>(topic: Topic, data?: T): void => {
  const message = JSON.stringify({ topic, data });

  if (_config.environment !== 'production') {
    log.info(message);
  }

  window.ReactNativeWebView?.postMessage(message);
};
