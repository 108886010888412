import { useCallback } from 'react';

import { SHOW_MODAL } from 'Reducers/modal/types';

import { store } from 'Utilities/store';

/**
 * This hook displays a modal when there is a failed API Call. This can occur in the case of
 * no internet or an unexpected error or API server being down.
 */
const useApiError = () => {
  const showApiErrorModal = useCallback(() => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        message: {
          msgKey: 'Global.modals.errorCommunicatingServer.body',
        },
      },
    });
  }, []);

  return { showApiErrorModal };
};

export default useApiError;
