import axios, { AxiosRequestConfig, Method } from 'axios';

import { mockInvoke } from 'Mock/api';

import { _config } from 'Utilities/envConfig';
import * as log from 'Utilities/log';
import mediator, { MediatorRequest } from 'Utilities/mediator';
import { getAuthSession } from 'Utilities/session';
import { getObject } from 'Utilities/storage';

// NOTE: replaced fetch with axios which is more standard and
// not plagued with some of the cross browser issues with fetch
const _invoke = <T>(
  method: Method,
  url: string,
  params: any,
  data?: T,
  auth?: { username: string; password: string },
  headers?: { [key: string]: string }
) => {
  return new Promise((resolve, reject) => {
    const request: AxiosRequestConfig<T> = {
      method,
      url,
      data,
      params,
      headers: {
        'Cache-control': 'no-cache',
        Pragma: 'no-cache',
        'Sharing-Web-Version': process.env.VERSION || '',
        ...headers,
      },
      auth,
      timeout: 30000, // 30s
    };
    const secret = getObject<string>('NEWYU_OPEN');

    if (secret && request.headers) {
      request.headers['newyu-open'] = secret;
    }

    const authTicket = getAuthSession();

    if (authTicket && request.headers) {
      request.headers.Authorization = 'Bearer ' + authTicket.access_token;
    }

    if (authTicket && request?.headers?.BasicAuth) {
      request.headers.Authorization = `Bearer ${authTicket.access_token},Basic ${request?.headers?.BasicAuth}`;
      delete request?.headers?.BasicAuth;
    }

    axios(request)
      .then((response) => {
        if (log.getLogLevel() === 'DEBUG') {
          log.groupAndTrace(
            `API: response for ${url}`,
            ['request', log.cleanObjectProperties(request)],
            ['response', response]
          );
        }

        // data is the body of the response
        // status is the status
        // statusText = "OK"
        // headers = {}
        // config - data used for request
        // request - the XMLHTTPRequest

        resolve(response);
      })
      .catch((error) => {
        if (log.getLogLevel() === 'DEBUG') {
          log.groupAndTrace(
            `API: Error response for ${url}`,
            ['request', log.cleanObjectProperties(request)],
            ['response', error]
          );
        }

        if (error.response) {
          return reject({ status: error.response.status, ...error });
        }

        reject({ status: 500 });
      });
  });
};

const _handleInvoke = <T>(request: MediatorRequest<T>) => {
  if (log.getLogLevel() === 'DEBUG') {
    log.debug('API: incomingRequest:' + request.url, log.cleanObjectProperties(request));
  }

  if (!request) {
    throw new Error('missing Request');
  }

  if (_config.mock) {
    mockInvoke(request);
    return;
  }

  // validate the request
  try {
    if (!request.method) {
      throw new Error('missing method(GET,POST,PUT,DELETE)');
    }

    if (!request.url) {
      throw new Error('missing url(users/create)');
    }

    request.response = _invoke<T>(
      request.method,
      request.url,
      request.params,
      request.data,
      request.auth,
      request.headers
    );
  } catch (ex) {
    request.response = new Promise((_resolve, reject) => {
      reject(ex);
    });
  }
};

mediator.subscribe('api:invoke', _handleInvoke);
